<template>
    <div style="overflow:auto">
       <div class="w-100 border-bottom" v-for="col in columns.filter(x=>x.parentGroupId == parentGroupId)">
            <div class="form-check ms-1 column-chooser-item">
                <input class="form-check-input" type="checkbox" v-model="col.shown" :id="'columnchooser_' + col.colId + id">
                <label class="form-check-label d-inline" :for="'columnchooser_' + col.colId + id"
                    :title="col.caption ?? col.field">
                    {{ col.caption ?? col.field }}
                </label>
            </div>
            <br>

            
        </div>
        <ColumnGroups v-for="child in getGroups(columns.filter(x=>x.parentGroupId != parentGroupId ))" :columns="columns.filter(x=>x.parentGroupId == child.parentGroupId)" :parentGroupId="child.parentGroupId"></ColumnGroups>
        <!-- Group name-->
        <!-- group childrens-->
    </div>
</template>
<script setup>
import {computed} from 'vue';
const props = defineProps(['columns','parentGroupId']);
const id = Math.round(Math.random() * 1000);
const getGroups = (columns)=>{
    const vParents = [];
    columns.forEach(col=>{
        if(!vParents.find(x=>x.parentGroupId === col.parentGroupId)){
            vParents.push(col);
        }
    })
    console.log("Parents: ",vParents);
    return vParents;
    
}

console.log(props.parentGroupId,props.columns);

</script>